import * as i from 'types';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { editMe } from 'ducks/me';
import { editMeLoading } from 'selectors/me';
import { Input, PasswordInput, SelectDropdown, DateInput } from 'common/form';
import { Heading } from 'common/typography';
import { Button } from 'common/interaction';
import { validation } from 'services';
import {
  useAuthenticationActions,
  useDispatch,
  useSelector,
} from 'services/hooks';

import { PageTransition } from 'common/layout/PageTransition';
import {
  PageWrapper,
  AddressContainer,
  ButtonContainer,
  HeadingContainer,
  StyledForm,
  FormContainer,
} from './styled';

export const AccountForm: React.FC<AccountFormProps> = ({ data }) => {
  const isLoading = useSelector(editMeLoading);
  const dispatch = useDispatch();
  const { logout } = useAuthenticationActions();
  const { t } = useTranslation();
  const { navigate } = useI18next();

  const genderOptions = [
    { label: 'Male', value: 'M' },
    { label: 'Female', value: 'F' },
  ];

  const selectedGender = (gender: string) => genderOptions.find((el) => el.value === gender);

  const defaultValues = {
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    username: data?.username || '',
    phone: data?.phone || '',
    address: data?.address || '',
    zip: data?.zip || '',
    city: data?.city || '',
    birthDate: data?.birthDate || '',
    password: '',
    gender: selectedGender(data.gender) || null,
  };

  const {
    register,
    reset,
    control,
    handleSubmit,
    formState:
      { isValid, errors, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const onSubmit = (data: i.UserEditData) => {
    const newGender = data.gender?.value;

    const formData: i.User = {
      ...data,
      gender: newGender,
    };

    dispatch(editMe(formData))
      .then((user) => {
        const newUserData = {
          ...user,
          gender: selectedGender(user.gender) || null,
        };

        reset(newUserData);
      })
      .catch((error: string) => {
        console.error(error);
      });
  };

  const handleLogout = () => {
    logout()
      .then(() => {
        reset();
        navigate('/');
      });
  };

  return (
    <PageTransition>
      <PageWrapper>
        <HeadingContainer>
          <Heading as="h2" variant={['gray', 'dark']}>{data?.firstName} {data?.lastName}</Heading>
        </HeadingContainer>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <FormContainer>
            <Heading as="h3" upperCase variant={['gray', 'dark']}>
              {t('personal')}
            </Heading>
            <Input
              label={t('first name')}
              defaultValue={defaultValues?.firstName}
              error={errors?.firstName}
              placeholder={t('your first name')}
              {...register('firstName', { ...validation.required })}
            />
            <Input
              label={t('last name')}
              defaultValue={defaultValues?.lastName}
              error={errors?.lastName}
              placeholder={t('your last name')}
              {...register('lastName', { ...validation.required })}
            />
            <Controller
              name="gender"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <SelectDropdown
                  {...{ onChange, value, name }}
                  options={genderOptions}
                  isSearchable={false}
                  isClearable={false}
                  label={t('gender')}
                />
              )}
            />
            <DateInput
              label={t('date of birth')}
              type="date"
              defaultValue={defaultValues?.birthDate}
              {...register('birthDate', { ...validation.date })}
              error={errors?.birthDate}
            />
            <Input
              label={t('phone')}
              defaultValue={defaultValues?.phone}
              {...register('phone', { ...validation.phone })}
              error={errors?.phone}
            />
            <Input
              label={t('emailaddress')}
              defaultValue={defaultValues?.username}
              {...register('username', { ...validation.email, ...validation.required })}
              error={errors?.username}
            />
          </FormContainer>
          <FormContainer>
            <Heading as="h3" upperCase variant={['gray', 'dark']}>
              {t('change password')}
            </Heading>
            <PasswordInput
              label={t('password')}
              error={errors?.password}
              placeholder="Password"
              autoComplete="current-password"
              {...register('password', { ...validation.password })}
            />
          </FormContainer>
          <FormContainer>
            <Heading as="h3" upperCase variant={['gray', 'dark']}>
              {t('address')}
            </Heading>
            <Input
              label={t('address')}
              defaultValue={defaultValues?.address}
              {...register('address')}
            />
            <AddressContainer>
              <Input
                label={t('zipcode')}
                defaultValue={defaultValues?.zip}
                {...register('zip')}
              />
              <Input
                label={t('city')}
                defaultValue={defaultValues?.city}
                {...register('city')}
              />
            </AddressContainer>
          </FormContainer>
          <ButtonContainer>
            <Button
              type="submit"
              size="fullWidth"
              disabled={!isDirty}
              $isValid={isValid && isDirty}
              isLoading={isLoading}
            >
              {t('save changes')}
            </Button>
            {isDirty ? (
              <Button
                type="button"
                variant="secondary"
                size="fullWidth"
                onClick={() => reset() }
              >
                {t('delete changes')}
              </Button>
            ) : (
              <Button
                type="button"
                variant="secondary"
                size="fullWidth"
                onClick={handleLogout}
              >
                {t('logout')}
              </Button>
            )}
          </ButtonContainer>
        </StyledForm>
      </PageWrapper>
    </PageTransition>
  );
};

type AccountFormProps = {
  data: i.User;
};
